<template>
<div>
  <!-- <v-row>
    <v-list three-line class="pb-0 px-5" color="transparent"> -->
      <template v-for="(offer) in offerList">
        <v-card :key="offer._id" class="pa-2 px-2 pt-3 mb-5"
                :class="'card-border ' + cardColor(offer) + '--border'">
          <OfferCard class="px-1" :offer="offer"></OfferCard>
          
          <v-row class="mb-0 mx-0">
            <template v-for="(proposition) in offer.propositions">
              <template>
                <v-col class="pa-0 px-1" :key="proposition._id">
                  <v-card elevation="0" :class="'card-border ' + cardColorProp(proposition) + '--border'" class="my-1">

                    <v-card-title class="teal--text font-led" style="font-size:22px;">
                      <v-gravatar height="24" class="mr-1" style="border-radius:50%;margin-bottom:-4px;"
                                  :default-img="'retro'" :email="proposition.userCaller.name"/>
                      {{ proposition.negos[proposition.negos.length-1].amount }} 
                      {{ $store.state.app.monyUnity }}
                    </v-card-title>

                    <v-card-subtitle class="pb-2">
                      Proposition de <b>{{ proposition.userCaller.name }}</b><br>
                      <small class="">Reçue
                      <time-ago :datetime="proposition.negos[proposition.negos.length-1].created" :refresh="60" long 
                                :locale="$vuetify.lang.current">
                      </time-ago>
                    </small>
                    </v-card-subtitle>
                    
                    <v-card-subtitle class="pt-0 d-none">
                      <b>
                      <v-chip v-if="proposition.negos[proposition.negos.length-1].status == 'OPEN'" 
                              color="orange darken-3" dark class="">
                        <v-icon small color="" class="mr-1">mdi-bell</v-icon>
                        Vous n'avez pas encore répondu
                      </v-chip>
                      <v-chip v-if="proposition.negos[proposition.negos.length-1].status == 'ACCEPTED'" 
                              color="green" dark>
                        <v-icon small color="" class="mr-1">mdi-thumb-up</v-icon>
                        Vous avez accepté cette proposition
                      </v-chip>
                      <v-chip v-if="proposition.negos[proposition.negos.length-1].status == 'REFUSED'" 
                              color="red" outlined dark>
                        <v-icon small color="" class="mr-1">mdi-close</v-icon>
                        Vous avez refusé cette proposition
                      </v-chip>
                      </b>
                    </v-card-subtitle>
                    
                    <v-card-text>

                      <v-card class="pa-3 py-1 my-2 mb-3" color="transparent" style="border:1px solid rgb(58, 57, 57)!important;" outlined elevation="0"
                          v-if="proposition.negos[proposition.negos.length-1].msgTxt || proposition.negos[proposition.negos.length-1].answerTxt">
                        <div class="d-inline-block mb-2" v-if="proposition.negos[proposition.negos.length-1].msgTxt">
                          <div class="py-0 pt-1" v-html="nl2br(proposition.negos[proposition.negos.length-1].msgTxt)"></div>
                        </div>
                        <div class="d-inline-block mb-2" v-if="proposition.negos[proposition.negos.length-1].answerTxt">
                          <v-gravatar height="20" class="mr-1" style="border-radius:50%;margin-bottom:-4px;"
                                        :default-img="'retro'" :email="offer.creator.name"/>
                          <b>Réponse de {{ offer.creator.name }}</b>
                          <br>
                          <div class="px-5 py-0 pt-1 ml-1" v-html="nl2br(proposition.negos[proposition.negos.length-1].answerTxt)"></div>
                        </div>
                      </v-card>

                        <v-btn color="blue lighten-1" class="mb-2 mr-2" outlined  rounded
                              @click="startConversation(proposition.userCaller._id)">
                          <v-icon color="" class="pr-1">mdi-message-outline</v-icon>
                          Contacter l'acheteur
                        </v-btn>
                        <v-btn  elevation="0" color="teal" class="mb-2" dark rounded
                              v-if="proposition.negos[proposition.negos.length-1].status == 'OPEN' || proposition.negos[proposition.negos.length-1].status == 'TOLATE'" 
                              @click="displayPopupAnswer(offer, proposition)" 
                              :disabled="offer.status != 'OPEN' && (proposition.negos[proposition.negos.length-1].status != 'TOLATE' || offer.status=='RESERVED')">
                              <v-icon  color="" class="mr-1">mdi-reply mdi-rotate-180</v-icon> Répondre
                        </v-btn> 
                        <v-btn  elevation="0" color="green" class="mb-2" dark rounded
                              v-if="proposition.negos[proposition.negos.length-1].status == 'ACCEPTED'">
                              <v-icon  color="" class="mr-1">mdi-check</v-icon> Proposition acceptée
                        </v-btn>

                    </v-card-text>

                  </v-card>
                </v-col>
              </template>
              
            </template>
          </v-row>
        </v-card>
      </template>
      
    <!-- </v-list>
  </v-row> -->

  <v-dialog v-model="showPopupAnswer" 
            v-if="currentProposition!=null" 
            content-class="dialog-large-bordered"
            :fullscreen="$vuetify.breakpoint.width < 600"
            max-width="700">
    <v-card>
      <v-card-title class="pl-3 pt-2">
        <v-icon class="mr-1">mdi-handshake</v-icon> 
        <small>Négociation</small><br>
        <!-- <small>Pour votre annonce : <i>{{ currentOffer.title }}</i></small> -->
      </v-card-title>

      <v-btn x-small fab elevation="0" color="transparent" style="float:right;margin:10px;" @click="showPopupAnswer=false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>

      <v-divider class="mb-0"/>

        <p class="pt-1 px-5 ma-0">
          <v-icon x-small class="mr-1">mdi-circle</v-icon> 
          <b><i>{{ currentOffer.title }}</i></b>
        </p>
        <p class="pb-1 pl-5 ma-0">
          Au prix initial de  
          <b class="pl-1 primary--text font-led d-inline-block" style="font-size:25px;">{{ currentOffer.amountMony }} {{ $store.state.app.monyUnity }}</b>
        </p>


      <v-divider class="mb-3"/>
      
      <template v-if="currentProposition.negos.length > 1">
        <small class="ml-5">
          <v-icon small class="mb-1 ml-1">mdi-clock</v-icon> 
          <v-icon x-small class="mb-1 ml-1">mdi-chevron-down</v-icon> 
          <b>Historique de la négociation</b>
        </small>
        <div class="mt-3">
          <template v-if="n < currentProposition.negos.length - 1"  >
            <v-card-subtitle class="pt-0"
                v-for="(nego) in currentProposition.negos" 
                :key="nego._id">
              <b>
                <v-icon x-small class="">mdi-chevron-right</v-icon> 
                {{ currentProposition.userCaller.name }}
              </b> 
              vous a proposé
              <b class="teal--text">{{ nego.amount }} {{ $store.state.app.monyUnity }}</b>   
              <v-chip color="red" outlined small class="ml-2" dark>Vous avez refusé</v-chip>
              <br>
              <i class="px-3" v-if="nego.msgTxt">"{{ nego.msgTxt }}"</i>
              <v-divider class="mt-2"/>
            </v-card-subtitle>
          </template>
        </div>

      </template>

      <v-card-subtitle class="pl-4 d-block text-center">
        <v-gravatar height="36" class="mr-2" style="border-radius:50%;margin-bottom:-5px;"
                        :default-img="'retro'" :email="currentProposition.userCaller.name"/><br>
        <b>{{ currentProposition.userCaller.name }}</b><br>
        vous propose <template v-if="currentProposition.negos.length > 1">maintenant</template> :<br><br>
        <b class="d-inline-block font-led teal--text ml-5" style="font-size:30px;">
          {{ currentProposition.negos[currentProposition.negos.length-1].amount }} {{ $store.state.app.monyUnity }}
        </b>
        
      </v-card-subtitle>

      <v-divider/>

      <template v-if="currentProposition.negos[currentProposition.negos.length-1].msgTxt">
        <v-card-title class="pb-0 px-5">
          <v-icon >mdi-comment-text-outline</v-icon>
          Son message :
        </v-card-title>

        <p class="px-5" v-html="nl2br(currentProposition.negos[currentProposition.negos.length-1].msgTxt)"></p>

        <v-divider/>
      </template>

      <v-card-text class="text-center mt-5 pb-0 px-2">
        
        <b>Acceptez-vous cette proposition ?</b>
        <v-textarea class="mt-4" outlined v-model="answerText"
                    label="Joindre un message..."></v-textarea>
      </v-card-text>


      <v-card-actions class="pa-3 pt-1 px-2 pb-2">
        <v-btn elevation="0" color="red" dark style="width:32%;margin:0 1% 0 0" @click="sendAnswer('REFUSED')" >
          <v-icon small>mdi-close</v-icon> <span class="d-none d-sm-inline">Refuser</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="teal" dark style="width:32%;margin:0" @click="showPopupAnswer=false" >
          <v-icon small>mdi-clock</v-icon> <span class="d-none d-sm-inline">Attendre</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="green" style="width:32%;margin:0 0 0 1%" dark @click="sendAnswer('ACCEPTED')">
          <v-icon small>mdi-check</v-icon> <span class="d-none d-sm-inline">Accepter</span>
        </v-btn>
      </v-card-actions>

      <v-col>
        <small class="red--text">
          Refuser : Tant que l'annonce est ouverte,
          {{ currentProposition.userCaller.name }} pourra vous faire d'autres propositions
        </small><br>
        <small class="teal--text">
          Attendre : Envoyer une réponse plus tard
        </small><br>
        <small class="green--text">
          Accepter : Attribuer l'annonce à {{ currentProposition.userCaller.name }} - 
          Aucune autre proposition ne pourra être faite
        </small>
      </v-col>
      
    </v-card>
  </v-dialog>

  <OfferDialog></OfferDialog>

</div>
</template>


<script>

import axios from 'axios'
import core from '../../plugins/core.js'
import Gravatar from 'vue-gravatar'
import {TimeAgo} from "vue2-timeago"

import OfferCard from '@/components/offer/OfferCard.vue'
import OfferDialog from '@/components/offer/OfferDialog.vue'

export default {
  name: 'home',
  components: {
     'v-gravatar' : Gravatar, TimeAgo, OfferCard, OfferDialog
  },
  data: () => ({
    showPopupAnswer: false,
    currentProposition: null,
    currentOffer: null,

    answerText: ""
  }),
  mounted: async function(){
    

  },
  methods: {
    async sendAnswer(answerType){
      let params = {  type: answerType, 
                      text: this.answerText,
                      propositionId: this.currentProposition._id 
                   }

      let res = await axios.post("/market/send-answer", params)
      if(res.data.error == false){
        //ferme la popup
        this.showPopupAnswer = false
        //actualise les données du user
        this.$store.dispatch('auth/refreshUserData')

        let txt = (answerType == "REFUSED") 
                ? 'Proposition refusée'
                : 'Vous pouvez maintenant transmettre vos coordonnées à votre acheteur'
        //affiche la notif dans la snackbar
        this.$store.dispatch('app/openSnackbar', 
                          { show: true, color:'green', 
                            title: "Votre réponse a bien été envoyée",
                            text: txt })

      }else{
        console.log("error sending answer")
        //affiche la notif dans la snackbar
        this.$store.dispatch('app/openSnackbar', 
                          { show: true, color:'red', 
                            title: "Une erreur est survenue pendant l'envoi de votre réponse",
                            text: 'Désolé pour ce problème, merci de réessayer plus tard' })
      }
      this.answerText = ""
    },
    startConversation(userId){
      this.$store.dispatch('app/openConv', { userId: userId }) 
      this.$root.$emit('closeDialogEntity')
    },
    async displayPopupAnswer(offer, proposition){
      this.currentProposition = proposition
      this.currentOffer = offer
      this.showPopupAnswer = true
    },
    cardColor(offer){
      if(offer.status == 'OPEN') return 'blue'
      if(offer.status == 'RESERVED') return 'green'
      if(offer.status == 'CLOSED') return 'red'
      if(offer.status == 'LOCKED') return 'red'
      return 'white' //this.cardColorProp(proposition)
    },
    cardColorProp(proposition){
      if(proposition.negos[proposition.negos.length-1].status == 'OPEN') return 'blue'
      if(proposition.negos[proposition.negos.length-1].status == 'ACCEPTED') return 'green'
      if(proposition.negos[proposition.negos.length-1].status == 'TOLATE') return 'blue'

      console.log("status", proposition.negos[proposition.negos.length-1].status)

      let refused = proposition.negos.filter(nego => nego.status == 'REFUSED')
      if(refused.length == proposition.negos.length) return 'red'
      
      return 'blue'
    },
    showOffer(offer){
      let refused = offer.propositions.filter(prop => prop.negos[prop.negos.length-1].status == 'REFUSED')
      return refused.length < offer.propositions.length
    },
    baseUrl(){ return core.baseUrl() },
    nl2br: function(str, is_xhtml){
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
      let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
      //let maxLength = 150
      // let p = newStr.length > maxLength ? '...' : ''
      // newStr = newStr.substr(0, maxLength) + p
      return newStr
    },
  },
  computed: {
    offerList(){
      return this.$store.getters['auth/myPropositionsReceived']
    }
  },
  watch: {
      
  }, 
}
</script>